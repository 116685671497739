@use '@amzn/awsui-design-tokens/polaris' as awsui;
@use './base';

// 912px is the container breakpoint-s, the viewport breakpoint is 912(container width) + 40(closed side navigation width) + 4(negative margin from grid)
$viewport-breakpoint-s: 957px;
$open-navigation-width: 240px;

.custom-home__header {
  background-color: awsui.$color-background-home-header;
}

.custom-home__header-title {
  color: awsui.$color-text-home-header-default;
}

.custom-home__category,
.custom-home__header-sub-title {
  color: awsui.$color-text-home-header-secondary;
}

@media only screen and (min-width: $viewport-breakpoint-s) {
  .custom-home__sidebar {
    // Aligns baseline of text with dark header bottom border
    margin-top: -86px;
  }
  .custom-home__header-cta {
    // Keeps 32px distance between sidebar cards
    margin-bottom: 38px;
  }
}

.custom-home__header-title > * {
  max-width: 700px;
}

.custom-home-image__placeholder:before {
  content: 'X';
  display: block;
  background-color: awsui.$color-background-input-disabled;
  color: awsui.$color-background-container-content;
  text-align: center;
  font-size: 400px;
  line-height: 400px;
}